import { Paper, Typography } from "@mui/material";
import React from "react";

const CreatePosts = () => {
    return (
        <Paper elevation={0} sx={{ padding: 2, height: 'calc(100vh - 50px)', width: 'calc(100vw - 322px)', overflow: 'auto' }}>
            <Typography variant="h5" gutterBottom>
                Create Posts
            </Typography>
            <Typography variant="body2" color="textSecondary" gutterBottom>
                Create and Publish Posts
            </Typography>
        </Paper>
    );
};

export default CreatePosts;
