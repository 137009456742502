import React, { useState } from 'react';
import { TextField, Button, Box, Typography, Paper } from '@mui/material';
import { useAuth } from '../context/AuthContext';
import { useNavigate } from 'react-router-dom';
import logo from '../images/logo.png';  // Import the logo image

const Login = () => {
    const { login } = useAuth();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const navigate = useNavigate();

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            await login(email, password);
            navigate('/');
        } catch (error) {
            alert('Login failed. Please check your credentials.');
        }
    };

    return (
        <Box display="flex" minHeight="100vh">
            {/* Left Half - Dark Gradient Background with Logo */}
            <Box
                flex={1}
                sx={{
                    background: 'linear-gradient(to bottom right, #1e1e2f, #2c2c44)',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    color: 'white',
                    p: 4,
                }}
            >
                <Box textAlign="center">
                    <img src={logo} alt="Logo" style={{ maxWidth: '50%', marginBottom: '20px' }} />
                </Box>
            </Box>

            {/* Right Half - Login Form */}
            <Box
                flex={1}
                display="flex"
                justifyContent="center"
                alignItems="center"
                sx={{
                    backgroundColor: '#f5f5f5',
                    padding: 4,
                }}
            >
                <Paper elevation={10} sx={{ padding: 4, borderRadius: 2, maxWidth: 400, width: '100%' }}>
                    <Typography variant="h5" align="center" gutterBottom sx={{ fontWeight: 'bold' }}>
                        Login
                    </Typography>
                    <Typography variant="subtitle1" align="center" color="textSecondary" gutterBottom>
                        Please enter your credentials
                    </Typography>
                    <form onSubmit={handleSubmit}>
                        <TextField
                            label="Email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            fullWidth
                            margin="normal"
                            variant="outlined"
                            InputProps={{
                                sx: { borderRadius: '8px' },
                            }}
                        />
                        <TextField
                            label="Password"
                            type="password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            fullWidth
                            margin="normal"
                            variant="outlined"
                            InputProps={{
                                sx: { borderRadius: '8px' },
                            }}
                        />
                        <Button
                            type="submit"
                            variant="contained"
                            color="primary"
                            fullWidth
                            sx={{
                                mt: 2,
                                py: 1.5,
                                fontWeight: 'bold',
                                borderRadius: '8px',
                                backgroundColor: '#1976d2',
                                '&:hover': {
                                    backgroundColor: '#115293',
                                },
                            }}
                        >
                            Login
                        </Button>
                    </form>
                </Paper>
            </Box>
        </Box>
    );
};

export default Login;
