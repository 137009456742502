import React from 'react';
import { Drawer, List, ListItem, ListItemText, ListItemIcon, Button, Divider, Typography, Box } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';

// Import Material UI Icons
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';
import PaymentIcon from '@mui/icons-material/Payment';
import PostAddIcon from '@mui/icons-material/PostAdd';
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import PeopleIcon from '@mui/icons-material/People';
import EditIcon from '@mui/icons-material/Edit';
import SubscriptionIcon from '@mui/icons-material/Subscriptions';
import LogoutIcon from '@mui/icons-material/Logout';
import logo from '../images/logo.png';

const Sidebar = () => {
    const navigate = useNavigate();
    const { logout } = useAuth();

    // Define icons for each menu item
    const menuItems = [
        { text: 'KYC Requests', icon: <AssignmentIndIcon />, path: '/kyc-requests' },
        { text: 'Payments', icon: <PaymentIcon />, path: '/payments' },
        { text: 'Create Post', icon: <PostAddIcon />, path: '/create-post' },
        { text: 'Edit Posts', icon: <EditIcon />, path: '/edit-posts' },
        { text: 'Create Notifications', icon: <NotificationsActiveIcon />, path: '/create-notifications' },
        { text: 'Users', icon: <PeopleIcon />, path: '/users' },
        { text: 'Edit Subscription Plans', icon: <SubscriptionIcon />, path: '/edit-subscription-plans' },
    ];

    const logoStyle = {
        width: '40px', // Adjust size as needed
        height: '40px',
        borderRadius: '50%', // Makes the image circular
        margin: '16px',
    };

    return (
        <Drawer
            variant="permanent"
            anchor="left"
            sx={{
                '& .MuiDrawer-paper': {
                    width: 272,
                    backgroundColor: '#1e1e2f',
                    color: '#fff',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'space-between',
                },
            }}
        >
            <Box>
                <img src={logo} alt="Logo" style={logoStyle} />
                <Divider sx={{ backgroundColor: '#4f4f5f' }} />
                <List>
                    {menuItems.map((item) => (
                        <ListItem
                            button
                            key={item.text}
                            onClick={() => navigate(item.path)}
                            sx={{
                                cursor: 'pointer',
                                '&:hover': {
                                    backgroundColor: '#333348',
                                },
                            }}
                        >
                            <ListItemIcon sx={{ color: '#bbb' }}>{item.icon}</ListItemIcon>
                            <ListItemText primary={item.text} />
                        </ListItem>
                    ))}
                </List>
            </Box>
            <Box p={2}>
                <Divider sx={{ backgroundColor: '#4f4f5f' }} />
                <ListItem button onClick={logout} sx={{
                    marginTop: 2, color: '#f44336', cursor: 'pointer',
                    '&:hover': {
                        backgroundColor: '#483333',
                    },
                }}>
                    <ListItemIcon>
                        <LogoutIcon sx={{ color: '#f44336' }} />
                    </ListItemIcon>
                    <ListItemText primary="Logout" />
                </ListItem>
            </Box>
        </Drawer>
    );
};

export default Sidebar;
