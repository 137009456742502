import React, { useEffect, useState } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { Box, CircularProgress, Typography, Avatar, IconButton, Paper } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import { useAuth } from '../context/AuthContext';

const KycRequests = () => {
    const { getRequest, postRequest } = useAuth();
    const [rowData, setRowData] = useState([]);
    const [loading, setLoading] = useState(true);

    const fetchKYCRequests = async () => {
        try {
            const response = await getRequest('/get-all-kyc-requests');
            console.log('KYC requests:', response);
            setRowData(response);
        } catch (error) {
            console.error('Error fetching KYC requests:', error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchKYCRequests();
    }, [getRequest]);

    const handleApprove = async (id) => {
        try {
            await postRequest('/approve-kyc-request', { kycRequestId: id });
            alert('KYC request approved successfully!');
            fetchKYCRequests();
        } catch (error) {
            console.error('Error approving KYC request:', error);
            alert('Failed to approve KYC request.');
        }
    };

    const handleReject = async (id) => {
        try {
            await postRequest('/reject-kyc-request', { kycRequestId: id });
            alert('KYC request rejected successfully!');
            fetchKYCRequests();
        } catch (error) {
            console.error('Error rejecting KYC request:', error);
            alert('Failed to reject KYC request.');
        }
    };

    const columns = [
        {
            field: 'name',
            headerName: 'Name',
            flex: 2,
            valueGetter: (params) => {
                const userId = params?.row?.userId;
                return userId ? `${userId.firstName || ''} ${userId.lastName || ''}`.trim() : '';
            },
        },
        {
            field: 'age',
            headerName: 'Age',
            flex: 1,
            valueGetter: (params) => params?.row?.userId?.age || '',
        },
        {
            field: 'location',
            headerName: 'Location',
            flex: 3,
            valueGetter: (params) => params?.row?.userId?.fullLocation || '',
        },
        {
            field: 'gender',
            headerName: 'Gender',
            flex: 1,
            valueGetter: (params) => params?.row?.userId?.gender || '',
        },
        {
            field: 'images',
            headerName: 'Images',
            flex: 3,
            renderCell: (params) => {
                const row = params?.row || {};
                return (
                    <Box display="flex" gap={1}>
                        <Avatar
                            alt="Govt. ID Front"
                            src={row.frontImage || ''}
                            variant="rounded"
                            sx={{ width: 44, height: 44, margin: .4, cursor: 'pointer' }}
                            onClick={() => window.open(row.frontImage, '_blank')}
                        />
                        <Avatar
                            alt="Govt. ID Back"
                            src={row.backImage || ''}
                            variant="rounded"
                            sx={{ width: 44, height: 44, margin: .4, cursor: 'pointer' }}
                            onClick={() => window.open(row.frontImage, '_blank')}
                        />
                        <Avatar
                            alt="User Selfie"
                            src={row.selfie || ''}
                            variant="rounded"
                            sx={{ width: 44, height: 44, margin: .4, cursor: 'pointer' }}
                            onClick={() => window.open(row.frontImage, '_blank')}
                        />
                    </Box>
                );
            },
        },
        {
            field: 'actions',
            headerName: 'Actions',
            flex: 2,
            renderCell: (params) => {
                const row = params?.row || {};
                return (
                    <Box display="flex" gap={1}>
                        <IconButton color="success" onClick={() => handleApprove(row._id)}>
                            <CheckCircleIcon />
                        </IconButton>
                        <IconButton color="error" onClick={() => handleReject(row._id)}>
                            <CancelIcon />
                        </IconButton>
                    </Box>
                );
            },
        },
    ];

    return (
        <Paper elevation={0} sx={{ padding: 2, height: 'calc(100vh - 50px)', width: 'calc(100vw - 322px)', overflow: 'auto' }}>
            <Typography variant="h5" gutterBottom>
                All KYC Requests
            </Typography>
            <Typography variant="body2" color="textSecondary" gutterBottom>
                Approve or Reject KYC Requests
            </Typography>
            {loading ? (
                <Box display="flex" justifyContent="center" alignItems="center" height="100%">
                    <CircularProgress />
                </Box>
            ) : (
                <DataGrid
                    rows={rowData}
                    columns={columns}
                    getRowId={(row) => row._id || Math.random().toString(36).substring(2)}
                    hideFooter
                    autoHeight
                    disableSelectionOnClick
                />
            )}
        </Paper>
    );
};

export default KycRequests;
