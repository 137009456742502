import { Box, Paper } from "@mui/material";
import React from "react";
import AllUsers from "../components/AllUsers";
import DeletedUsers from "../components/DeletedUsers";
import ReportedUsers from "../components/ReportedUsers";

const Users = () => {
    return (
        <Paper elevation={0} sx={{ height: 'calc(100vh - 50px)', width: 'calc(100vw - 322px)', overflow: 'auto' }}>
            <AllUsers />
            <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                <DeletedUsers />
                <ReportedUsers />
            </Box>
        </Paper>
    );
};

export default Users;
