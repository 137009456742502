// src/components/Payments.js
import React, { useEffect, useState } from 'react';
import { Box, Paper, Typography, CircularProgress, IconButton } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { CheckCircle, Cancel } from '@mui/icons-material';
import moment from 'moment';
import { useAuth } from '../context/AuthContext';

const Payments = () => {
    const { getRequest } = useAuth();
    const [rowData, setRowData] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchPayments = async () => {
            try {
                const response = await getRequest('/get-all-payments');
                setRowData(response);
            } catch (error) {
                console.error('Error fetching payments:', error);
            } finally {
                setLoading(false);
            }
        };
        fetchPayments();
    }, [getRequest]);

    const columns = [
        {
            field: 'orderId',
            headerName: 'Order ID',
            flex: 2,
            cellClassName: 'cell-left',
        },
        {
            field: 'subscription',
            headerName: 'Subscription Name',
            flex: 2,
            valueGetter: (params) => params?.title,
            filterable: true,
        },
        {
            field: 'user',
            headerName: 'User Name',
            flex: 2,
            valueGetter: (params) => `${params?.firstName || ''} ${params?.lastName || ''}`.trim(),
            filterable: true,
        },
        {
            field: 'createdAt',
            headerName: 'Created At',
            flex: 2,
            valueGetter: (params) => moment(params).format("Do MMM YY"),
        },
        {
            field: 'paid',
            headerName: 'Paid',
            flex: 1,
            renderCell: (params) => (
                params?.value ? <IconButton color="success"><CheckCircle /></IconButton> :
                    <IconButton color="error"><Cancel /></IconButton>
            ),
            filterable: true,
        },
        {
            field: 'active',
            headerName: 'Active',
            flex: 1,
            renderCell: (params) => (
                params?.value ? <IconButton color="success"><CheckCircle /></IconButton> :
                    <IconButton color="error"><Cancel /></IconButton>
            ),
            filterable: true,
        },
    ];

    return (
        <Paper elevation={0} sx={{ padding: 2, height: 'calc(100vh - 50px)', width: 'calc(100vw - 322px)', overflow: 'auto' }}>
            <Typography variant="h5" gutterBottom>
                All Payments
            </Typography>
            <Typography variant="body2" color="textSecondary" gutterBottom>
                View all payment transactions
            </Typography>
            {loading ? (
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        height: '100%',
                    }}
                >
                    <CircularProgress />
                </Box>
            ) : (
                <DataGrid
                    rows={rowData}
                    columns={columns}
                    getRowId={(row) => row?.orderId}
                    hideFooter
                    disableSelectionOnClick
                />
            )}
        </Paper>
    );
};

export default Payments;
