// src/App.js
import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { AuthProvider, useAuth } from './context/AuthContext';
import Sidebar from './components/Sidebar';
import Login from './components/Login';
import KycRequests from './screens/KycRequests';
import Payments from './screens/Payments';
import CreatePosts from './screens/CreatePosts';
import EditPosts from './screens/EditPosts';
import CreateNotifications from './screens/CreateNotifications';
import Users from './screens/Users';
import EditSubscriptionPlans from './screens/EditSubscriptionPlans';

const ProtectedRoute = ({ children }) => {
  const { token } = useAuth();
  return token ? children : <Navigate to="/login" />;
};

const App = () => {
  return (
    <AuthProvider>
      <Router>
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route
            path="*"
            element={
              <ProtectedRoute>
                <div style={{ display: 'flex' }}>
                  <Sidebar />
                  <main style={{ marginLeft: 272, padding: 16 }}>
                    <Routes>
                      {/* Add your routes for each page here */}
                      <Route path="/kyc-requests" element={<KycRequests />} />
                      <Route path="/payments" element={<Payments />} />
                      <Route path="/create-post" element={<CreatePosts />} />
                      <Route path="/edit-posts" element={<EditPosts />} />
                      <Route path="/create-notifications" element={<CreateNotifications />} />
                      <Route path="/users" element={<Users />} />
                      <Route path="/edit-subscription-plans" element={<EditSubscriptionPlans />} />
                      <Route path="*" element={<Navigate to="/kyc-requests" />} />
                      {/* ...other routes */}
                    </Routes>
                  </main>
                </div>
              </ProtectedRoute>
            }
          />
        </Routes>
      </Router>
    </AuthProvider>
  );
};

export default App;
