import React, { useEffect, useState } from 'react';
import { Box, Paper, Typography, CircularProgress } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { useAuth } from '../context/AuthContext';
import moment from 'moment';

const ReportedUsers = () => {
    const { getRequest } = useAuth();
    const [rowData, setRowData] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchUsers = async () => {
            try {
                const response = await getRequest('/get-all-reported-users');
                console.log('Reported users:', response);

                const formattedData = response
                    .filter(item => item.user && item.reportedUser)
                    .map(({ user, reportedUser, reason, createdAt, _id }) => ({
                        id: _id,
                        reportedUserName: `${reportedUser.firstName} ${reportedUser.lastName}`,
                        reportedByName: `${user.firstName} ${user.lastName}`,
                        reason,
                        reportedAt: moment(createdAt).format("Do MMM YY"),
                    }));

                setRowData(formattedData);
            } catch (error) {
                console.error('Error fetching reported users:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchUsers();
    }, [getRequest]);

    const columns = [
        {
            field: 'reportedUserName',
            headerName: 'Reported User',
            flex: 2,
        },
        {
            field: 'reportedByName',
            headerName: 'Reported By',
            flex: 2,
        },
        {
            field: 'reason',
            headerName: 'Reason',
            flex: 3,
        },
        {
            field: 'reportedAt',
            headerName: 'Reported At',
            flex: 1.5,
        },
    ];

    return (
        <Paper elevation={0} sx={{ padding: 2, height: 'calc(50% - 74px)', width: 'calc(50%)' }}>
            <Typography variant="h5" gutterBottom>
                Reported Users
            </Typography>
            <Typography variant="body2" color="textSecondary" gutterBottom>
                View all reported user information
            </Typography>
            <Box sx={{ height: '100%', width: '100%' }}>
                {loading ? (
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            height: '100%',
                        }}
                    >
                        <CircularProgress />
                    </Box>
                ) : (
                    <DataGrid
                        rows={rowData}
                        columns={columns}
                        getRowId={(row) => row.id}
                        hideFooter
                        disableSelectionOnClick
                    />
                )}
            </Box>
        </Paper>
    );
};

export default ReportedUsers;
