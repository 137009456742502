import React, { createContext, useState, useContext } from 'react';
import axios from 'axios';

const AuthContext = createContext();

export const BASE_URL = 'https://andwemet2-0-backend-mwhmysv7xa-el.a.run.app/api/admin';

export const AuthProvider = ({ children }) => {
    const [token, setToken] = useState(localStorage.getItem('token') || null);

    const login = async (email, password) => {
        try {
            const response = await axios.post(`${BASE_URL}/login`, { email, password });
            setToken(response.data.token);
            localStorage.setItem('token', response.data.token);
        } catch (error) {
            console.error('Login failed:', error);
            throw error;
        }
    };

    const logout = () => {
        setToken(null);
        localStorage.removeItem('token');
    };


    // Function for GET requests with authorization header
    const getRequest = async (endpoint) => {
        try {
            const response = await axios.get(`${BASE_URL}${endpoint}`, {
                headers: { Authorization: `Bearer ${token}` },
            });
            return response.data;
        } catch (error) {
            console.error('GET request failed:', error);
            throw error;
        }
    };

    // Function for POST requests with authorization header
    const postRequest = async (endpoint, data) => {
        try {
            const response = await axios.post(`${BASE_URL}${endpoint}`, data, {
                headers: { Authorization: `Bearer ${token}` },
            });
            return response.data;
        } catch (error) {
            console.error('POST request failed:', error);
            throw error;
        }
    };

    // Function for PUT requests with authorization header
    const putRequest = async (endpoint, data) => {
        try {
            const response = await axios.put(`${BASE_URL}${endpoint}`, data, {
                headers: { Authorization: `Bearer ${token}` },
            });
            return response.data;
        } catch (error) {
            console.error('PUT request failed:', error);
            throw error;
        }
    };

    return (
        <AuthContext.Provider value={{ token, login, logout, getRequest, postRequest, putRequest }}>
            {children}
        </AuthContext.Provider>
    );
};

export const useAuth = () => useContext(AuthContext);
