import React, { useEffect, useState } from 'react';
import { Box, Paper, Typography, CircularProgress } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { useAuth } from '../context/AuthContext';

const AllUsers = () => {
    const { getRequest } = useAuth();
    const [rowData, setRowData] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchUsers = async () => {
            try {
                const response = await getRequest('/get-all-users');
                setRowData(response);
            } catch (error) {
                console.error('Error fetching all users:', error);
            } finally {
                setLoading(false);
            }
        };
        fetchUsers();
    }, [getRequest]);

    const columns = [
        {
            field: 'displayName',
            headerName: 'Display Name',
            flex: 1.5,
            cellClassName: 'cell-left',
        },
        {
            field: 'firstName',
            headerName: 'First Name',
            flex: 2,
        },
        {
            field: 'lastName',
            headerName: 'Last Name',
            flex: 2,
        },
        {
            field: 'age',
            headerName: 'Age',
            flex: 1,
        },
        {
            field: 'fullLocation',
            headerName: 'Location',
            flex: 3,
        },
        {
            field: 'gender',
            headerName: 'Gender',
            flex: 1,
        },
    ];

    return (
        <Paper elevation={0} sx={{ padding: 2, height: 'calc(50% - 52px)' }}>
            <Typography variant="h5" gutterBottom>
                All Users
            </Typography>
            <Typography variant="body2" color="textSecondary" gutterBottom>
                View all user information
            </Typography>
            <Box sx={{ height: '100%', width: '100%' }}>
                {loading ? (
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            height: '100%',
                        }}
                    >
                        <CircularProgress />
                    </Box>
                ) : (
                    <DataGrid
                        rows={rowData}
                        columns={columns}
                        getRowId={(row) => row?.id || `${row?.firstName}-${row?.lastName}`}
                        hideFooter
                            disableSelectionOnClick
                            sx={{ height: 'calc(100% - 74px)' }}
                    />
                )}
            </Box>
        </Paper>
    );
};

export default AllUsers;
