import React, { useEffect, useState } from 'react';
import { Box, Paper, Typography, IconButton, TextField, Button, Modal, Chip, FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { Edit as EditIcon } from '@mui/icons-material';
import { useAuth } from '../context/AuthContext';

const SubscriptionPlans = () => {
    const { getRequest, putRequest } = useAuth();
    const [plans, setPlans] = useState([]);
    const [loading, setLoading] = useState(true);
    const [editModalOpen, setEditModalOpen] = useState(false);
    const [selectedPlan, setSelectedPlan] = useState(null);
    const [descriptionInput, setDescriptionInput] = useState('');

    useEffect(() => {
        const fetchPlans = async () => {
            try {
                const response = await getRequest('/get-all-payment-plans');
                setPlans(response);
            } catch (error) {
                console.error('Error fetching subscription plans:', error);
            } finally {
                setLoading(false);
            }
        };
        fetchPlans();
    }, [getRequest]);

    const handleEditClick = (plan) => {
        setSelectedPlan({ ...plan });
        setEditModalOpen(true);
    };

    const handleDescriptionAdd = () => {
        if (descriptionInput) {
            setSelectedPlan({
                ...selectedPlan,
                description: [...selectedPlan.description, descriptionInput],
            });
            setDescriptionInput('');
        }
    };

    const handleDescriptionDelete = (chipToDelete) => {
        setSelectedPlan({
            ...selectedPlan,
            description: selectedPlan.description.filter((desc) => desc !== chipToDelete),
        });
    };

    const handleSaveChanges = async () => {
        try {
            await putRequest(`/edit-payment-plan/${selectedPlan._id}`, selectedPlan);
            setPlans(plans.map((plan) => (plan._id === selectedPlan._id ? selectedPlan : plan)));
            setEditModalOpen(false);
        } catch (error) {
            console.error('Error updating plan:', error);
        }
    };

    const columns = [
        { field: 'title', headerName: 'Title', flex: 2 },
        { field: 'description', headerName: 'Description', flex: 3, renderCell: (params) => params.value.join(', ') },
        { field: 'validityTime', headerName: 'Validity Time', flex: 1 },
        { field: 'validityPer', headerName: 'Validity Period', flex: 1 },
        { field: 'amount', headerName: 'Amount', flex: 1 },
        { field: 'currency', headerName: 'Currency', flex: 1 },
        {
            field: 'actions',
            headerName: 'Actions',
            flex: 1,
            renderCell: (params) => (
                <IconButton onClick={() => handleEditClick(params.row)}>
                    <EditIcon />
                </IconButton>
            ),
        },
    ];

    return (
        <Paper elevation={0} sx={{ padding: 2, width: 'calc(100vw - 322px)', overflow: 'auto' }}>
            <Typography variant="h5" gutterBottom>
                Edit Subscription Plans
            </Typography>
            <Typography variant="body2" color="textSecondary" gutterBottom>
                Edit Subscription Plans
            </Typography>
            <DataGrid
                rows={plans}
                columns={columns}
                getRowId={(row) => row._id}
                loading={loading}
                disableSelectionOnClick
                hideFooter
            />
            {/* Edit Modal */}
            {selectedPlan && (
                <Modal open={editModalOpen} onClose={() => setEditModalOpen(false)}>
                    <Paper
                        sx={{
                            position: 'absolute',
                            top: '50%',
                            left: '50%',
                            transform: 'translate(-50%, -50%)',
                            width: 640,
                            p: 4,
                        }}
                    >
                        <Typography variant="h6" gutterBottom>
                            Edit {selectedPlan.title}
                        </Typography>
                        <TextField
                            label="Title"
                            value={selectedPlan.title}
                            onChange={(e) => setSelectedPlan({ ...selectedPlan, title: e.target.value })}
                            fullWidth
                            margin="normal"
                        />
                        <TextField
                            label="Amount"
                            type="number"
                            value={selectedPlan.amount}
                            onChange={(e) => setSelectedPlan({ ...selectedPlan, amount: parseInt(e.target.value, 10) })}
                            fullWidth
                            margin="normal"
                        />
                        <FormControl fullWidth margin="normal">
                            <InputLabel>Validity Period</InputLabel>
                            <Select
                                value={selectedPlan.validityPer}
                                onChange={(e) => setSelectedPlan({ ...selectedPlan, validityPer: e.target.value })}
                            >
                                <MenuItem value="day">Day</MenuItem>
                                <MenuItem value="week">Week</MenuItem>
                                <MenuItem value="month">Month</MenuItem>
                                <MenuItem value="year">Year</MenuItem>
                            </Select>
                        </FormControl>
                        <TextField
                            label="Validity Time"
                            type="number"
                            value={selectedPlan.validityTime}
                            onChange={(e) => setSelectedPlan({ ...selectedPlan, validityTime: parseInt(e.target.value, 10) })}
                            fullWidth
                            margin="normal"
                        />

                        {/* Description as Chips */}
                        <Box display="flex" flexWrap="wrap" gap={1} mt={2} mb={2}>
                            {selectedPlan.description.map((desc, index) => (
                                <Chip
                                    key={index}
                                    label={desc}
                                    onDelete={() => handleDescriptionDelete(desc)}
                                    color="primary"
                                />
                            ))}
                        </Box>
                        <TextField
                            label="Add Description"
                            value={descriptionInput}
                            onChange={(e) => setDescriptionInput(e.target.value)}
                            fullWidth
                            margin="normal"
                        />
                        <Button onClick={handleDescriptionAdd} variant="contained" sx={{ mt: 1 }}>
                            Add
                        </Button>

                        <Box mt={3} display="flex" justifyContent="space-between">
                            <Button variant="contained" color="primary" onClick={handleSaveChanges}>
                                Save Changes
                            </Button>
                            <Button variant="outlined" onClick={() => setEditModalOpen(false)}>
                                Cancel
                            </Button>
                        </Box>
                    </Paper>
                </Modal>
            )}
        </Paper>
    );
};

export default SubscriptionPlans;
